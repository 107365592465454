import Image from "next/image";
import classNames from "classnames";
import Link from "next/link";
import styles from "./index.module.less";
import RetroTitle from "@/components/Common/RetroTitle";
import { AppSettings } from "@/shared/app-common";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
const GlobalFooter = ({
  show = true,
  className,
}: {
  show?: boolean;
  className?: string;
}) => {
  const { t } = useTranslation("common");
  const router = useRouter();

  if (!show) {
    return <></>;
  }
  const cls = classNames(styles.globalFooterWarp, className);
  // const pathname = (typeof window !== 'undefined') ? window.location.pathname : "/"

  return (
    <div className={cls}>
      <div className={styles.titleContainer}>
        <div className={styles.logo}>
          <img src={AppSettings.logoPath} alt="" />
        </div>
        {/* GPTs Logo */}
        <div className={styles.section}>
          <Link href={`${AppSettings.webHost}`} className={styles.link}>
            {t("Home")}
          </Link>
          <Link href={`${AppSettings.webHost}/plan`} className={styles.link}>
            {"Pricing"}
          </Link>

          <Link href={`${AppSettings.webHost}/blog`} className={styles.link}>
            {t("blog")}
          </Link>
        </div>
        {/* 
        <div className={styles.sectionIcon}>
          <Link
            href="https://twitter.com/DolphinRadar"
            target="__block"
            className={styles.link}
          >
            <img src="/images/layouts/x-link-icon.webp" alt="x" />
          </Link>
          <Link
            href="https://www.facebook.com/profile.php?id=61558394549930"
            target="__block"
            className={styles.link}
          >
            <img src="/images/layouts/fb-link-icon.webp" alt="facebook" />
          </Link>
        </div> */}
        <div className={styles.circle}>
          <Link
            href="https://medium.com/@erasaprotect"
            target="__block"
            className={styles.link}
          >
            <img src="/images/home/bottomIcon/circle_two.webp" alt="medium" />
          </Link>
          <Link
            href="https://www.facebook.com/profile.php?id=61565526387773"
            target="__block"
            className={styles.link}
          >
            <img src="/images/home/bottomIcon/facebook.webp" alt="facebook" />
          </Link>
          <Link
            href="https://x.com/Erasaprotect"
            target="__block"
            className={styles.link}
          >
            <img src="/images/home/bottomIcon/twitter.webp" alt="twitter" />
          </Link>
          <Link
            href="https://www.youtube.com/@Erasa-protect"
            target="__block"
            className={styles.link}
          >
            <img src="/images/home/bottomIcon/youtobe.webp" alt="youtobe" />
          </Link>
        </div>
      </div>

      <div className={styles.bottom}>
        <div className={styles.subTitleContainer}>
          All rights reserved. © 2024 Erasa
        </div>
        <div className={styles.policy}>
          <span
            onClick={() => {
              router.push("/privacy-policy");
            }}
          >
            {t("privacyPolicy")}
          </span>
          <span>|</span>
          <span
            onClick={() => {
              router.push("/terms-us");
            }}
          >
            {t("termsOfUs")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GlobalFooter;
