import { CaretDownOutlined } from "@ant-design/icons";
import styles from "./index.module.less";
import { useRouter } from "next/router";
import classNames from "classnames";
import Image from "next/image";
import { useLocation } from "react-use";
import { useEffect } from "react";
import { useConfigProvider } from "@/context/ConfigProvider";
import { ga_logEvent } from "@/shared/ga";

export interface MarketplaceNavItem {
  text: string;
  path?: string;
  gaName?: string;
  icon?: { left: string; right: string };
  dropdownItems?: MarketplaceNavItem[];
  onClick?: (item: MarketplaceNavItem) => void;
}

const NavItem = ({
  item,
  className,
}: {
  item: MarketplaceNavItem;
  className?: string;
}) => {
  const { isMobile } = useConfigProvider();
  const router = useRouter();
  const location = useLocation();

  const getColor = (pathStr: string) => {
    let end = location?.pathname?.toLowerCase() == pathStr?.toLowerCase();
    return end ? "#428DFF" : `var(--app-text-color)`;
  };

  const handleItemClick = (item: MarketplaceNavItem) => {
    if (item.gaName) {
      ga_logEvent(item?.gaName);
    }
    if (item?.onClick) {
      item.onClick(item);
      return;
    }
    router.push(item?.path || "/");
  };

  const renderItem = (item: MarketplaceNavItem, index?: string | number) => {
    return (
      <div
        className={classNames(styles.navItemWarp, className)}
        key={index}
        onClick={() => handleItemClick(item)}
      >
        <div
          className={isMobile ? styles.navItemMobileText : styles.navItemText}
          style={{
            color: getColor(item?.path),
          }}
        >
          {item.text}
        </div>
        {item.dropdownItems && <CaretDownOutlined />}
        {item.dropdownItems && (
          <div className={styles.navDropdown}>
            {item.dropdownItems.map((childItem, cIndex) =>
              renderItem(childItem, `nav-dropdown-item-${cIndex}`),
            )}
          </div>
        )}
      </div>
    );
  };

  return renderItem(item, `nav-item`);
};
export default NavItem;
