import React, { useCallback, useEffect, useState } from "react";
import style from "./index.module.less";
import { useRouter } from "next/router";
import classNames from "classnames";
import Image from "next/image";
import { useUserProvider } from "@/context/UserProvider";
import { message } from "antd";
import AddLinkModal from "@/components/leaksFound/AddLinkModal/index";
const Index: React.FC<any> = () => {
  const { userInfo, onSignOut, userSub } = useUserProvider();

  const [isOpenLinkModal, setIsOpenLinkModal] = useState(false);

  const router = useRouter();
  const menuList = [
    {
      url: "/dashboard",
      name: "Dashboard",
    },
    {
      url: "/leaks-found",
      name: "Leaks Found",
      add: true,
    },
    {
      url: "/safe-links",
      name: "Safelinks",
    },
    {
      url: "/protected-Accounts",
      name: "Protected Accounts",
    },
    {
      url: "/setting",
      name: "Setting",
    },
  ];
  // 跳转弹窗
  const handleOpneAddLinkModal = (e: any) => {
    e.stopPropagation();
    setIsOpenLinkModal(true);
  };

  return (
    <div className={style.container}>
      <div className={style.head} onClick={() => router.push("/")}>
        <Image
          src={"/images/layouts/erasa.png"}
          height={50}
          width={200}
          alt="erasa"
        ></Image>
      </div>
      <div className={style.line}></div>
      <ul className={style.tabList}>
        {menuList.map((item, index) => (
          <li
            className={classNames(style.tabItem, {
              [style.actice]: item.url == router.pathname,
            })}
            key={index}
            onClick={() => router.push(item.url)}
          >
            <Image
              src={`/Images/dashboard/df/${index}-${
                item.url == router.pathname ? "1" : "0"
              }.svg`}
              height={20}
              width={20}
              alt=""
              className={style.icon}
            ></Image>
            <span className={style.name}>{item.name}</span>
            {item.add && (
              <span className={style.add} onClick={handleOpneAddLinkModal}>
                +
              </span>
            )}
          </li>
        ))}
      </ul>
      <div className={style.bottom}>
        <div>
          <div className={style.uif}>
            {userInfo?.avatar ? (
              <div>
                <img src={userInfo?.avatar} alt="avatar" />
              </div>
            ) : (
              <div>{userInfo?.avatar ? userInfo?.first_name : "E"}</div>
            )}
            <div>
              <div>
                {userInfo?.first_name
                  ? userInfo?.first_name +
                    (userInfo?.last_name ? userInfo?.last_name : "")
                  : userInfo?.email?.split("@")[0]}
              </div>
              <div>{userInfo?.email}</div>
            </div>
          </div>
          <div className={style.action}>
            {/*正常 */}
            {(userSub?.status == "normal" || userSub?.status == "active") && (
              <div className={style.ac1}>
                <div>
                  <img src="/images/dashboard/df/vip.webp" alt="" />
                  <span>{userSub?.name}</span>
                </div>
                <div onClick={() => router.push("/setting")}>
                  <img src="/images/dashboard/df/right.svg" alt="" />
                </div>
              </div>
            )}
            {/* 过期 */}
            {userSub?.status == "canceled" && (
              <div className={style.ac2}>
                <div>
                  <img src="/images/dashboard/df/error.webp" alt="" />
                  <span>Deactivated</span>
                </div>
                <div onClick={() => router.push("/setting")}>
                  <img src="/images/dashboard/df/right.svg" alt="" />
                </div>
              </div>
            )}
            {userSub?.status == "expire" && (
              <div className={style.ac3}>
                <div>
                  <img src="/images/dashboard/df/errVip.webp" alt="" />
                  <span>Expired</span>
                </div>
                <div>Renew</div>
              </div>
            )}
            {userSub?.status == "trialing" && (
              <div className={style.ac1}>
                <div>
                  <img src="/images/dashboard/df/vip.webp" alt="" />
                  <span>Free trial</span>
                </div>
                <div onClick={() => router.push("/setting")}>
                  <img src="/images/dashboard/df/right.svg" alt="" />
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <div onClick={() => router.push("/")}>
            <Image
              src={"/images/dashboard/home.svg"}
              height={20}
              width={20}
              alt="home"
            />
          </div>
          <div
            onClick={() => {
              onSignOut();
              message.success("logout success");
              router.push("/");
            }}
          >
            <Image
              src={"/images/dashboard/back.svg"}
              height={20}
              width={20}
              alt="home"
            />
          </div>
        </div>
      </div>
      <AddLinkModal
        isAddLinkModal={isOpenLinkModal}
        handleClose={() => {
          setIsOpenLinkModal(false);
        }}
        upDataList={() => {}}
      ></AddLinkModal>
    </div>
  );
};

export default Index;
