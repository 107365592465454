import { Modal } from "antd";
import style from "./index.module.less";
import { CloseOutlined } from "@ant-design/icons";
import { ReactElement } from "react";
interface Iprops {
  isModalOpen: boolean;
  handleOk?: () => void;
  handleCancel: () => void;
  children: ReactElement;
  width?: number;
  closeIcon?: boolean;
  isNoAddAccount?: boolean;
  isMask?: boolean;
  isCenter?: boolean;
}

const Index: React.FC<Iprops> = ({
  isModalOpen,
  handleOk,
  handleCancel,
  children,
  width,
  closeIcon = true,
  isNoAddAccount = true,
  isMask = true,
  isCenter = false,
}) => {
  return (
    <Modal
      title="Basic Modal"
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      width={width}
      closable={false}
      mask={isMask}
      centered={isCenter}
    >
      <div
        className={style.container}
        style={{ padding: isNoAddAccount ? "10px" : "0px" }}
      >
        {closeIcon && (
          <div className={style.close} onClick={handleCancel}>
            <CloseOutlined />
          </div>
        )}
        {children}
      </div>
    </Modal>
  );
};

export default Index;
